import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { SidebarStyles } from "./sidebar.style";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import DialogMlReport from "../../../../pages/admin/ml-report.dialog";

const Sidebar = ({ setActiveSidebar, activeSidebar }) => {
  const [openMlReportDialog, setOpenMlReportDialog] = useState(false);
  const styles = SidebarStyles();
  const navigate = useNavigate();

  const handleRedirectPath = (path) => {
    navigate(path);
    setActiveSidebar(!activeSidebar);
  };

  return (
    <Drawer variant="permanent" sx={styles.wrapperDrawer}>
      <List sx={{ backgroundColor: "black", color: "white" }}>
        <ListItem>
          <IconButton
            aria-label="close menu"
            sx={{ color: "white", margin: "auto" }}
            onClick={() => {
              setActiveSidebar(!activeSidebar);
            }}
          >
            <CloseIcon />
          </IconButton>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ANNUAL RATES"
            onClick={() => {
              handleRedirectPath("/admin/annualrates");
            }}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="VIEW ADMINS"
            onClick={() => {
              handleRedirectPath("/admin/dashboard?view=admins");
            }}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="VIEW CUSTOMERS"
            onClick={() => {
              handleRedirectPath("/admin/dashboard?view=customers");
            }}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ACTIVE PROS"
            onClick={() => {
              handleRedirectPath("/admin/active-pros");
            }}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="PACKAGE"
            onClick={() => {
              handleRedirectPath("/admin/package");
            }}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ML REPORT"
            onClick={() => setOpenMlReportDialog(true)}
            sx={styles.itemList}
          />
        </ListItem>
      </List>
      {openMlReportDialog && (
        <DialogMlReport
          openDialog={openMlReportDialog}
          setOpenDialog={setOpenMlReportDialog}
        />
      )}
    </Drawer>
  );
};

Sidebar.propTypes = {
  setActiveSidebar: PropTypes.func.isRequired,
  activeSidebar: PropTypes.bool.isRequired,
};

export default Sidebar;
