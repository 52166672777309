export const columns = [
  {
    field: 'name',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Gym Name',
    flex: 0.8,
    disableColumnMenu: true,
  },
  {
    field: 'city',
    headerAlign: 'center',
    align: 'center',
    headerName: 'City',
    disableColumnMenu: true,
    flex: 0.6,
    renderCell: (params) => <>{params.row.city ? params.row.city : 'N/A'}</>,
  },
  {
    field: 'state',
    headerAlign: 'center',
    headerName: 'State',
    flex: 1,
    align: 'center',
    disableColumnMenu: true,
  }
]