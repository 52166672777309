import { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Grid, Typography, Button, Dialog, TextField } from '@mui/material';
import {
  BtnActionsDlgAsk, ContentSectionDialog,
  TitleSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomerService from '../../core/services/customer.service';

const AskDropDialog = ({
  data,
  locationId,
  openDialog,
  setOpenDialog,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);
  const [dropDate, setDropDate] = useState(dayjs(new Date()));
  const [dropReason, setDropReason] = useState('');

  const customerService = new CustomerService();

  const dropAccount = async (id) => {
    try {
      setLoading(true);
      await customerService.dropProvider(id, { dropped_at: dropDate, dropped_reason: dropReason, businessId: locationId })
      .then(() => {
        setOpenDialog(false);
        return onSuccess();
      }).finally(() => {
        setLoading(false);
      });
    } catch (error) {
      setErrror(true);
      console.log(error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <TitleSectionDialog>Wait!</TitleSectionDialog>
        <ContentSectionDialog>
          <Box sx={{ mb: 2}}>
            <Typography>
              {`Are you sure to drop this business account?`}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Payment agreement date"
                  value={dropDate}
                  onChange={(newValue) => setDropDate(newValue)}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Reason"
                onChange={(e) => setDropReason(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </ContentSectionDialog>
        {error && <Alert severity="warning">{error.message || 'Failed to drop the customer, try again.'}</Alert>}
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant='outlined'
            color='primary'
            onClick={() => {
              dropAccount(data);
            }}
          >
            Drop
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default AskDropDialog;

AskDropDialog.propTypes = {
  data: PropTypes.number,
  locationId: PropTypes.number,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};
