import { serialize } from 'object-to-formdata';
import AppService from './AppService';

class CustomerService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/customers');
  }

  getCustomers({ order = '', direction = '', page = 1, paymentStatus = '', provider = '' }) {
    return this.http.get(
      `${this.path}?render=paginate&page=${page}&orderBy=${order}&orderDir=${direction}&filterByProvider=${provider}&filterBy=${paymentStatus}`
    );
  }

  getCustomer(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  editCustomer(id, data) {
    const formData = serialize({...data, _method: 'PATCH'});
    return this.http.post(`${this.path}/${id}`, formData);
  }

  searchCustomers(data) {
    return this.http.get(`${this.path}?render=paginate&searchBy=${data}`);
  }

  deleteCustomer(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  deleteLocation(id) {
    return this.http.delete(`${this.path}/business/${id}`);
  }

  downloadAgreement(id) {
    return this.http.get(`${this.path}/download/${id}`, { responseType: 'blob' });
  }

  getOneCustomer(businessId) {
    return this.http.get(`${this.path}/account/${businessId}`);
  }

  downloadCustomerAgreement(link) {
    return this.http.get(link, { responseType: 'blob' });
  }

  downloadAllFiles(){
    return this.http.get(`${this.path}/download/8`, { responseType: 'blob' });
  }

  stripeAccount(id) {
    return this.http.post(`${this.path}/${id}/stripe-portal`);
  }

  downloadDocusignFile(link) {
    return this.http.get(link, { responseType: 'blob' });
  }

  dropProvider(id, body) {
    return this.http.patch(`${this.path}/drop/${id}/`, body);
  }

}



export default CustomerService;

