import React, { useEffect, useState } from 'react';

import { debounce as _debounce } from 'lodash';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box, FormControl, InputLabel, MenuItem,
  Select, TextField, Typography,
} from '@mui/material';

import { useUI } from '../../core/context/ui';
import AppHelper from '../../core/helpers/AppHelper';
import CustomerService from '../../core/services/customer.service';
import ProvidersService from '../../core/services/providers.service';
import { DashboardStyles } from '../assets/styles/dashboard.styles';
import { DashboardContext } from './dashboard.table';

const CustomFilter = () => {
  const { snackbarUI } = useUI();
  const {
    rowsState,
    setRowsState,
    accessToken,
    paymentStatus,
    setPaymentStatus,
    providerSelected,
    setProviderSelected,
    getCustomers,
    sortModel,
  } = React.useContext(DashboardContext);
  const [providers, setProviders] = useState([]);
  const providersService = new ProvidersService();
  const [flag, setFlag] = useState(false);

  const styles = DashboardStyles();
  const customerService = new CustomerService();

  const handleSearch = async (e) => {
    const { value } = e.target;
    if (!value) {
      getCustomers({});
    } else {
      try {
        customerService.setAccessToken(accessToken);
        const r1 = await customerService.searchCustomers(value);
        setRowsState({
          ...rowsState,
          rows: r1.data.data,
          rowCount: Number(r1.data.recordsTotal),
          page: Number(r1.data.page),
        });
      } catch (error) {
        AppHelper.checkError(error, snackbarUI);
      }
    }
  };

  const debouncedSubmit = _debounce(handleSearch, 1000);

  const handleFiltersBy = () => {
    getCustomers({
      order: sortModel.field || '',
      direction: sortModel.sort || '',
      page: rowsState.page + 1,
      paymentStatus: paymentStatus,
      provider: providerSelected,
    });
  };

  const getProviders = async () => {
    try {
      const response = await providersService.getProviders();
      setProviders(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flag) handleFiltersBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus, providerSelected, flag]);

  return (
    <Box sx={styles.filtersBox}>
      <TextField
        variant='outlined'
        size='small'
        InputProps={{ startAdornment: <SearchIcon /> }}
        placeholder='Search gym, email...'
        onChange={debouncedSubmit}
        style={{ width: '30%' }}
      />
      <Typography style={{ marginLeft: '24px' }}>Filter by:</Typography>
      <FormControl size='small' style={{ minWidth: 200, margin: '0 16px' }}>
        <InputLabel id='status'>Payment Status</InputLabel>
        <Select
          id='status'
          label='Payment Status'
          value={paymentStatus}
          inputProps={{ name: 'filterBy' }}
          onChange={(e) => {
            setFlag(true);
            setPaymentStatus(e.target.value);
          }}
          style={{ padding: '0 20px' }}
        >
          <MenuItem value={0}>All Payment Status</MenuItem>
          <MenuItem value='active'>Active</MenuItem>
          <MenuItem value='inactive'>Inactive</MenuItem>
          <MenuItem value='drop'>Drop</MenuItem>
        </Select>
      </FormControl>
      <FormControl size='small' style={{ minWidth: 200, margin: '0 16px' }}>
        <InputLabel id='status'>Licensing</InputLabel>
        <Select
          inputProps={{ name: 'filterByProvider' }}
          id='licensing'
          label='Licensing'
          value={providerSelected}
          style={{ padding: '0 20px' }}
          onChange={(e) => {
            setFlag(true);
            setProviderSelected(e.target.value);
          }}
        >
          <MenuItem value={0}>All Licensing</MenuItem>
          {React.Children.toArray(
            providers &&
              providers.map((item) => (
                // eslint-disable-next-line react/jsx-key
                <MenuItem value={item.id}>{item.shortName}</MenuItem>
              ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomFilter;
