import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import {
  BoxTicketMain,
  TitleBox,
} from "../assets/styles/account.styles.js";
import AppHelper from "../../core/helpers/AppHelper.js";
import { useSelector } from "react-redux";
import { useUI } from "../../core/context/ui.js";
import LoaderWidget from "../../core/widgets/loader/loader.widget.js";
import AnnotationsService from "../../core/services/annotations.service.js";
import AnnotationDialog from "./annotationDialog.js";
import { Check, PlayArrow } from "@mui/icons-material";
import { EditIcon } from "../../core/widgets/MaterialUI.js";

const Annotations = () => {
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;
  const { snackbarUI } = useUI();
  const params = useParams();
  const [annotations, setAnnotations] = useState([]);
  const [reload, setReload] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState(false);

  const annotationsService = new AnnotationsService();

  const getAnnotations = async (businessId) => {
    setLoader(true);
    try {
      annotationsService.setAccessToken(accessToken);
      const response = await annotationsService.getByBusiness(businessId);
      if (response.status === 200) {
        setAnnotations(response.data);
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAnnotations(params.businessId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, reload]);

  return (
    <Fragment>
      <BoxTicketMain>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TitleBox>Annotations</TitleBox>
                  <Button
                    sx={{ color: "white" }}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => { setSelectedAnnotation({}); setIsOpenDialog(true); }}
                  >
                    ADD NEW
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                {
                  annotations.length ? 
                  <List>
                    {
                      annotations.map((annotation) => (
                        <ListItem key={annotation.id}>
                          <ListItemAvatar>
                            <Avatar>
                              {!annotation.isCompleted ? <PlayArrow color="secondary" /> : <Check color="secondary" />}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Typography variant="subtitle1">{annotation?.description}</Typography>}
                            secondary={<Typography variant="caption">Created at {new Date(annotation?.createdAt).toDateString()}</Typography>}
                          />
                          <IconButton onClick={() => { setSelectedAnnotation(annotation); setIsOpenDialog(true); }}>
                            <EditIcon fontSize={'small'} />
                          </IconButton>
                        </ListItem>
                      ))
                    }
                  </List> : <Box>No annotations</Box>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BoxTicketMain>
      <LoaderWidget loader={loader} />
      {
        isOpenDialog && (
        <AnnotationDialog
          openDialog={isOpenDialog}
          setOpenDialog={setIsOpenDialog}
          locationId={params.businessId}
          onSuccess={setReload}
          annotation={selectedAnnotation}
        />)
      }
    </Fragment>
  );
};

export default Annotations;
