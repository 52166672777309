import { useState } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import download from '../../assets/images/download.svg';
import { DashboardStyles } from '../assets/styles/dashboard.styles';
import DashboardTable from './dashboard.table.js';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import ReportsService from '../../core/services/reports.service';
import AppHelper from '../../core/helpers/AppHelper';
import { useUI } from '../../core/context/ui';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import DialogMonthlyReport from '../admin/monthly-report.dialog.js';

const Dashboard = () => {

  const { snackbarUI } = useUI();
  const [loader, setLoader] = useState(false);
  const reportsService = new ReportsService();
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;

  // monthly report dialog
  const [openMRDialog, setOpenMRDialog] = useState(false);

  const ridersType = [
    { id: '1', riderType: 'ascap' },
    { id: '2', riderType: 'sesac' },
    { id: '3', riderType: 'bmi' },
    { id: '4', riderType: 'monthly' },
    { id: '5', riderType: 'annotations' }
  ];

  const getRiders = async (riderType) => {
    try {
      reportsService.setAccessToken(accessToken);
  
      if (riderType === 'monthly') {
        return setOpenMRDialog(true);
      }
  
      setLoader(true);
      let response;
      let fileName;
  
      if (riderType === 'annotations') {
        response = await reportsService.getAnnotationsReport();
        fileName = `annotations_${Moment().format('YYYYMMDDHms')}.xlsx`;
      } else {
        response = await reportsService.getRiders(riderType);
        fileName = `${riderType}_rider_${Moment().format('YYYYMMDDHms')}.xlsx`;
      }
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const file = document.createElement('a');
      file.href = url;
      file.download = fileName;
      file.click();
  
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    } finally {
      setLoader(false);
    }
  };

  const styles = DashboardStyles();
  return (
    <>
      <Container style={{ maxWidth: '95%' }}>
        <Box sx={styles.titleWrap}>
          <Box>
            <Typography variant='h1' sx={styles.title}>
              Licensing
            </Typography>
            <Typography variant='h4' sx={styles.subTitle}>
              Search all gyms with music licensing
            </Typography>
          </Box>
          <Box sx={styles.wrapBtn}>
          {ridersType.map((data) => (
            <Button key={data.id} onClick={() => getRiders(`${data.riderType}`)} sx={[styles.btn, styles.btnPrimaryLight, styles.downloadBtn]}>
                <Box component='img' src={download} sx={styles.ico} />
                {data.riderType} {(data.riderType !== 'monthly' && data.riderType !== 'annotations') ? 'Rider' : 'Report'}
              </Button>
          ))}
          </Box>
        </Box>
        <DashboardTable />
      </Container>
      {
        openMRDialog && (      
        <DialogMonthlyReport
          openDialog={openMRDialog}
          setOpenDialog={setOpenMRDialog}
        />)
      }
      <LoaderWidget loader={loader} />
    </>
  );
};

export default Dashboard;
