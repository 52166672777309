import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Typography, Button, Dialog, Autocomplete, TextField, Box, Grid } from '@mui/material';
import {
  BtnActionsDlgAsk, ContentSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import CustomerService from '../../core/services/customer.service';
import { useSelector } from 'react-redux';
import BusinessService from '../../core/services/business.service';
import AppHelper from '../../core/helpers/AppHelper';
import SnackBarUI from '../../core/widgets/SnackbarUI';

const MergeLocationDialog = ({
  openDialog,
  data,
  setOpenDialog,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useSelector((state) => state.user);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [availableToMerge, setAvailableToMerge] = useState([]);
  const accessToken = user?.token;
  const customerService = new CustomerService();
  const businessService = new BusinessService();

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAvailableToMerge();
    }, 500);
  
    return () => clearTimeout(delayDebounceFn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const getAvailableToMerge = async () => {
    if (!searchTerm) return;
    setLoading(true);
    try {     
      customerService.setAccessToken(accessToken)
      const response = await businessService.availableToMerge(searchTerm);
      if(response.status === 200 || response.status === 201) {
        setAvailableToMerge(Array.isArray(response.data) ? response.data : []);
      }
    } catch (e) {
      AppHelper.checkError(e, SnackBarUI);
    } finally {
      setLoading(false);
    }
  };

  const handleMergeLocations = async() => {
    if (!selectedBusiness) return;
    try {
      setLoading(true);
      const merge = await businessService.mergeLocation({ 
        new_user_id: data,
        business_id: selectedBusiness?.id,
        last_user_id: selectedBusiness?.userId 
      });
      if(merge.status === 200 || merge.status === 201) {
        console.log('Merged');
        window.location.reload();
      }
    } catch(e) {
      AppHelper.checkError(e, SnackBarUI);
    } finally {
      setLoading(false);
    }
  }

  return (
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
        maxWidth="md"
        fullWidth
      >
        <ContentSectionDialog>
          <Typography variant="h6">
            Merge locations
          </Typography>
          <Typography>
            {`Are you sure to add new location for this account?`}
          </Typography>
          <Box sx={{ paddingBottom: 3, paddingTop: 3 }}>
            <Autocomplete
              loading={loading}
              options={availableToMerge}
              getOptionLabel={(option) => option.name}
              inputValue={searchTerm}
              value={selectedBusiness}
              onChange={(event, newValue) => setSelectedBusiness(newValue)}
              onInputChange={(event, value) => setSearchTerm(value)}
              sx={{ width: 300 }}
              noOptionsText={loading ? "Cargando..." : "Sin options"}
              renderInput={(params) => <TextField {...params} label="Search by business name" />}
            />
          </Box>
          {
            selectedBusiness ?
            <Grid container>
            <Grid item xs={6}>
              <Box>
                <Typography variant='subtitle2'>Full name</Typography>
                <Typography variant='subtitle1'>{selectedBusiness?.fullName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant='subtitle2'>Email</Typography>
                <Typography variant='subtitle1'>{selectedBusiness?.email}</Typography>
              </Box>
            </Grid>
          </Grid> : null
          }
        </ContentSectionDialog>
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
              setSelectedBusiness(null);
              setSearchTerm('');
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedBusiness || loading}
            variant='outlined'
            color='primary'
            onClick={() => {
              handleMergeLocations()
            }}
          >
            Confirm
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
  );
};

export default MergeLocationDialog;

MergeLocationDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  data: PropTypes.number,
};
