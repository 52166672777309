import { useState } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Alert, Box, Grid, Typography, Button, Dialog } from '@mui/material';
import {
  BtnActionsDlgAsk, ContentSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReportsService from '../../core/services/reports.service';
import dayjs from 'dayjs';

const DialogMlReport = ({
  openDialog,
  setOpenDialog,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);
  const [year, setYear] = useState();

  const reportService = new ReportsService();

  const download = async () => {
    try {
      setErrror(false);
      setLoading(true);
      const response = await reportService.getCustomersReport(year)
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url; 
      file.download = `music_licensing_report_${Moment().format('YYYYMMDD')}.xlsx`;
      file.click();
      setOpenDialog(false);
    } catch (error) {
      setErrror(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <ContentSectionDialog>
          <Box sx={{ mb: 2}}>
            <Typography>
              Customers Report
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Select a year'}
                  minDate={dayjs('2024-01-01')}
                  maxDate={dayjs()}
                  openTo="year"
                  views={['year']}
                  sx={{ width: '100%' }}
                  onYearChange={(newValue) => setYear(newValue.year())}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </ContentSectionDialog>
        {error && <Alert severity="warning">{error.message || 'Failed to download the report, try again.'}</Alert>}
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant='outlined'
            color='primary'
            onClick={() => {
              download();
            }}
          >
            Download
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default DialogMlReport;

DialogMlReport.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};
