import { useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  BtnActionsDlgAsk,
  ContentSectionDialog,
} from "../assets/styles/admin-dashboard.dialog.styles";
import AnnotationsService from "../../core/services/annotations.service";
import { useSelector } from "react-redux";

const AnnotationDialog = ({
  locationId,
  openDialog,
  setOpenDialog,
  onSuccess,
  annotation,
}) => {
  const isEdit = Object.keys(annotation).length;

  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);
  const [errorMessage, setErrrorMessage] = useState(false);
  const [description, setDescription] = useState(annotation?.description || "");
  const [isCompleted, setIsCompleted] = useState(
    annotation?.isCompleted || false
  );
  const { user } = useSelector((state) => state.user);
  const annotationsService = new AnnotationsService();

  const onConfirm = async () => {
    setErrror(false);
    setErrrorMessage("");
    try {
      setLoading(true);
      await annotationsService
        .add({
          description,
          isCompleted: false,
          businessId: locationId,
          createdBy: user.id,
        })
        .then(({data}) => {
          setOpenDialog(false);
          return onSuccess(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrror(true);
      setErrrorMessage(error.message);
      console.log(error);
    }
  };

  const onUpdate = async () => {
    setErrror(false);
    setErrrorMessage("");
    try {
      setLoading(true);
      await annotationsService
        .edit(annotation.id, {
          description,
          is_completed: isCompleted,
        })
        .then(({data}) => {
          setOpenDialog(false);
          return onSuccess(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrror(true);
      setErrrorMessage(error.message);
      console.log(error);
    }
  };

  const onClickAction = () => {
    isEdit ? onUpdate() : onConfirm();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    setIsCompleted(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: "16px" } }}
      >
        <ContentSectionDialog>
          <Box sx={{ mb: 2 }}>
            <Typography>
              {isEdit ? `Edit Annotation` : `New Annotation`}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Annotation"
                defaultValue={annotation?.description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            {isEdit ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isCompleted}
                      onChange={handleChange}
                    />
                  }
                  label="Is completed"
                />
              </Grid>
            ) : null}
          </Grid>
        </ContentSectionDialog>
        {error && (
          <Alert severity="warning">
            {errorMessage || "Failed to process the annotation, try again."}
          </Alert>
        )}
        <BtnActionsDlgAsk>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="outlined"
            color="primary"
            onClick={() => {
              onClickAction();
            }}
          >
            {isEdit ? "Confirm" : "Save"}
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default AnnotationDialog;

AnnotationDialog.propTypes = {
  locationId: PropTypes.string,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  annotation: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    isCompleted: PropTypes.bool,
  }),
};
