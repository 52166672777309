import { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import {
  BoxLine,
  BoxTicketMain,
  ButtonReturn,
  ContainerAccount,
  LabelName,
  LabelTextTitle,
} from "../assets/styles/account.styles.js";
import CustomerService from "../../core/services/customer.service.js";
import AppHelper from '../../core/helpers/AppHelper.js';
import { useSelector } from 'react-redux';
import { useUI } from '../../core/context/ui.js';
import LoaderWidget from '../../core/widgets/loader/loader.widget.js';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from './columns.js';
import CustomNoRowsOverlay from '../../assets/CustomNoRowsOverlay.js';
import { DashboardStyles } from '../assets/styles/dashboard.styles.js';
import MergeLocationDialog from './mergeLocations.dialog.js';

const LocationsPage = () => {
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [openMergeDialog, setOpenMergeDialog] = useState(false);
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;
  const { snackbarUI } = useUI();
  const params = useParams()
  const styles = DashboardStyles();
  const [customerData, setCustomerData] = useState({})
  const customerService = new CustomerService();

  const getOneCustomer = async (id) => {
    setLoader(true);
    try {
      customerService.setAccessToken(accessToken)
      const response = await customerService.getCustomer(id);
      if(response.status === 200 || response.status === 201) {
        setCustomerData(response.data);
        setBusinessData(response.data.business);
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOneCustomer(params.id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Fragment>
      <Container>
        <ContainerAccount>
          <ButtonReturn onClick={() => navigate('/admin/dashboard')}>
            Back
          </ButtonReturn>
          <BoxTicketMain>
            <Grid sx={{ paddingBottom: 4 }} container>
              <Grid container>
                <Grid item xs={12} sx={{ justifyContent: 'end', display: 'flex', paddingBottom: 4 }}>
                  {
                    !isProduction && <Button
                    onClick={() => {setOpenMergeDialog(true)}}
                    variant="outlined">
                    Merge locations
                  </Button>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h1' sx={{...styles.title, paddingBottom: 3}}>
                    User Information
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelName>Name</LabelName>
                    <LabelTextTitle>{customerData.fullName}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelName>Title</LabelName>
                    <LabelTextTitle>{customerData.title}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelName>Phone</LabelName>
                    <LabelTextTitle>{customerData.phoneNumber}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelName>Email</LabelName>
                    <LabelTextTitle style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                      {customerData.email}
                    </LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelName>Payment method</LabelName>
                    <LabelTextTitle style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                      { Object.keys(customerData).length && customerData?.pmType.charAt(0).toUpperCase() + customerData?.pmType.slice(1)}
                    </LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelName>Locations</LabelName>
                    <LabelTextTitle style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                      {Object.keys(customerData).length && customerData?.business.length}
                    </LabelTextTitle>
                  </Box>
                </Grid>
              </Grid>
              <BoxLine />
            </Grid>
            <Grid item sx={{ height: 'auto' }}>
              <Typography variant='h1' sx={{...styles.title, paddingBottom: 3}}>
                Locations
              </Typography>
            </Grid>
            <Grid item>
              <DataGrid
                sx={styles.dataGridStyles}
                autoHeight
                rows={businessData}
                columns={columns}
                headerHeight={46}
                rowHeight={46}
                onRowClick={(event) => { navigate(`/admin/location/${event.row.id}`)} }
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  NoResultsOverlay: CustomNoRowsOverlay,
                }}
                disableSelectionOnClick
                checkboxSelection={false}
                hideFooterPagination
              />
            </Grid>
          </BoxTicketMain>
        </ContainerAccount>
      </Container>
      <LoaderWidget loader={loader} />
      {
        openMergeDialog &&
        <MergeLocationDialog 
          data={customerData.id}
          openDialog={openMergeDialog}
          setOpenDialog={setOpenMergeDialog}
        />
      }
    </Fragment>
  );
};

export default LocationsPage;
