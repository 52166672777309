import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { theme } from '../../../theme';

export const DialogCreate = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '900px',
    maxWidth: '900px',
    padding: '56px 64px',
  },
});

export const TitleSectionDialog = styled(DialogTitle)({
  textAlign: 'center',
  fontSize: '28px',
  fontWeight: '700',
  fontFamily: 'Montserrat Bold',
  padding: 0,
  marginBottom: '20px',
});

export const ContentSectionDialog = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .label': {
    fontSize: '14px',
    fontFamily: 'Montserrat Bold',
    marginBottom: '12px',
  },
  '& .subtitle': {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    marginBottom: '32px',
  },
  '& .formAdmin': {
    padding: '0 32px',
  },
});

export const BtnActionsDlgAsk = styled(DialogActions)({
  marginTop: '24px',
  '& button': {
    borderRadius: '5px',
    borderColor: 'rgba(94,100,105, 0.5)',
    width: '100%',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '10px',
    color: '#5E6469',
    '&:hover': {
      borderColor: 'rgba(94,100,105, 0.5)',
    },
  },
  '& button + button, & button + button:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    borderColor: theme.palette.primary.main,
  },
});

export const BtnActionsDlgScss = styled(DialogActions)({
  '& button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    width: '100%',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '10px',
    '&:hover': {
      borderColor: 'rgba(94,100,105, 0.5)',
    },
  },
  '& button:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    borderColor: theme.palette.primary.main,
  },
});
