import AppService from './AppService';

class AnnotationsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/annotations');
  }
  
  getByBusiness(id) {
    return this.http.get(`${this.path}/business/${id}`);
  }

  add(data) {
    return this.http.post(`${this.path}`, data);
  }

  edit(id, body) {
    return this.http.put(`${this.path}/${id}`, body);
  }

}

export default AnnotationsService;
