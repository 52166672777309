import AppService from './AppService';

class ReportsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/reports');
  }

  getRiders(riderType){
    return this.http.get(`${this.path}/agreements/${riderType}`, { responseType: 'blob' });
  }

  getCustomersReport(year){
    return this.http.get(`${this.path}/customers?year=${year}`, { responseType: 'blob' });
  }
  
  getStatementReport(startMonth, endMonth, year, isBmiQuarters){
    return this.http.get(`${this.path}/statement?start_month=${startMonth}&end_month=${endMonth}&year=${year}&bmi_quarters=${isBmiQuarters}`, { responseType: 'blob' });
  }

  getAnnotationsReport(){
    return this.http.get(`${this.path}/annotations`, { responseType: 'blob' });
  }

}

export default ReportsService;
