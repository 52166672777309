import { useState } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Alert, Box, Grid, Typography, Button, Dialog, FormControlLabel, Switch } from '@mui/material';
import {
  BtnActionsDlgAsk, ContentSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReportsService from '../../core/services/reports.service';
import dayjs from 'dayjs';

const DialogMonthlyReport = ({
  openDialog,
  setOpenDialog,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [year, setYear] = useState();
  const [isCheckedSwitch, setIsCheckedSwitch] = useState(false);

  const reportService = new ReportsService();

  /* const formatMonthName = (monthValue) => {
    const startMonthName = new Date(new Date().getFullYear, monthValue - 1);
    return new Intl.DateTimeFormat("en-US", { month: "short" }).format(startMonthName);
  } */

  const download = async () => {
    try {
      setErrror(false);
      setLoading(true);
      const response = await reportService.getStatementReport(startMonth, endMonth, year, isCheckedSwitch)
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      // const startMonthName = formatMonthName(startMonth);
      // const endMonthName = formatMonthName(endMonth);
      let fileName = startMonth === endMonth ? `${startMonth}-${year}`: `${startMonth}-${endMonth}-${year}`; 
      file.download = `statement-report-${fileName}-${Moment().format('YYYYMMDDHms')}.xlsx`;
      file.click();
      setOpenDialog(false);
    } catch (error) {
      setErrror(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    setIsCheckedSwitch(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <ContentSectionDialog>
          <Box sx={{ mb: 2}}>
            <Typography>
              Monthly Report
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Select a year'}
                  minDate={dayjs('2024-01-01')}
                  maxDate={dayjs()}
                  openTo="year"
                  views={['year']}
                  sx={{ width: '100%' }}
                  onYearChange={(newValue) => setYear(newValue.year())}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Start month'}
                  disabled={!year}
                  openTo="month"
                  views={['month']}
                  sx={{ width: '100%' }}
                  minDate={dayjs(`${year}-01-01`)}
                  maxDate={dayjs(`${year}-12-01`)}
                  onMonthChange={(newValue) => setStartMonth(newValue.month() + 1)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'End month'}
                  disabled={!year && !startMonth}
                  openTo="month"
                  views={['month']}
                  sx={{ width: '100%' }}
                  minDate={dayjs(`${year}-${startMonth}-01`)}
                  maxDate={dayjs(`${year}-12-01`)}
                  onMonthChange={(newValue) => setEndMonth(newValue.month() + 1)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel 
                control={
                  <Switch
                    checked={isCheckedSwitch} 
                    onChange={handleChange}
                  />
                }
                label="BMI Quarters"
              />
            </Grid>
          </Grid>
        </ContentSectionDialog>
        {error && <Alert severity="warning">{error.message || 'Failed to download the report, try again.'}</Alert>}
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant='outlined'
            color='primary'
            onClick={() => {
              download();
            }}
          >
            Download
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default DialogMonthlyReport;

DialogMonthlyReport.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};
